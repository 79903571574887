import axios from "axios";
import React from "react";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Snackbar } from "../../components/Snackbar";
import { useAuth } from "../../contexts/AuthContext";
import { useMoodle } from "../../contexts/MoodleContext";
import { useTheme } from "../../contexts/ThemeContext";
import platform from "platform";
import {
  DEFAULT_SNACKBAR,
  ERROR_TICKET_FORM,
  ERROR_TICKET_SERVER,
  SUCCESS_TICKET,
} from "../../utils/snackbar";
import { defaultHandleChange } from "../../utils/handler";
import "./styles.css";

let url = "https://tuclase.freshdesk.com/api/v2/tickets";
let headers = {
  "Content-Type": "application/json",
  Authorization: `Basic clFNZjdybTVOaG1odHZmZ21ERDpY`,
};

const ContactForm = () => {
  const auth = useAuth();
  const moodle = useMoodle();
  const theme = useTheme();
  /*   console.log(platform);
  console.log({ moodle }); */
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const [isMultiCourses, setMultiCourses] = React.useState(false);
  const [userData, setUserData] = React.useState(auth.user);
  const [ticket, setTicket] = React.useState({
    description: "",
    subject: "",
    name: `${auth.user.fullname}`,
    email: auth.user.email,
    priority: 1,
    group_id: 62000092292,
    status: 2,
    custom_fields: {
      cf_rut: auth.user.idnumber,
      cf_nota: "",
    },
    cc_emails: [],
  });
  const [loading, setLoading] = React.useState(false);
  /*   const [sentResponse, setSentResponse] = React.useState(null); */

  React.useEffect(() => {
    !moodle.courses && moodle.getCourses();
    moodle.courses && handleCourseSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moodle]);

  /* se agrega use effect, para cuando no llegue el campo firstname (se asume que tampoco biene last name) */
  React.useEffect(() => {
    if (auth.user && !auth.user?.firstname) {
      let fname = auth.user.fullname.split(" ")[0];
      let lname = auth.user.fullname.split(" ")[1];
      setUserData({ ...userData, firstname: fname, lastname: lname });
    }
    //eslint-disable-next-line
  }, [auth.user]);

  const handleCourseSelected = () => {
    moodle.courses.length === 1
      ? setTicket({
          ...ticket,
          custom_fields: {
            ...ticket.custom_fields,
            cf_nota: `Id Curso: ${moodle.courses[0].id} - Nombre Curso: ${moodle.courses[0].fullname} - Navegador: ${platform.name} - OS: ${platform.description}`,
          },
        })
      : setMultiCourses(true);
  };

  const handleCourseSelection = (e) => {
    if (e.target.value === "") {
      setTicket({
        ...ticket,
        custom_fields: {
          ...ticket.custom_fields,
          cf_nota: "",
        },
      });
      return;
    }
    const course = moodle.courses.find((c) => c.id === Number(e.target.value));

    /*   console.log(course); */

    setTicket({
      ...ticket,
      custom_fields: {
        ...ticket.custom_fields,
        cf_nota: `Id Curso: ${course.id} - Nombre Curso: ${course.fullname} - Navegador: ${platform.name} - OS: ${platform.description}`,
      },
    });
  };

  const handleSnackbar = (snack) => {
    setSnackbar(snack);
    setTimeout(() => {
      setSnackbar(DEFAULT_SNACKBAR);
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    /*     console.log({ ticket }); */
    if (ticket.subject === "") {
      handleSnackbar(ERROR_TICKET_FORM);
      return;
    }
    if (ticket.description === "") {
      handleSnackbar(ERROR_TICKET_FORM);
      return;
    }
    if (ticket.custom_fields.cf_curso === "") {
      handleSnackbar(ERROR_TICKET_FORM);
      return;
    }
    setLoading(true);
    try {
      await axios.post(url, ticket, { headers });
      setLoading(false);
      setTicket({
        ...ticket,
        description: "",
        subject: "",
        custom_fields: {
          ...ticket.custom_fields,
          cf_nota: "",
        },
      });

      handleSnackbar(SUCCESS_TICKET);
      /*       console.log({ data }); */
    } catch (err) {
      console.log(err);
      setLoading(false);
      handleSnackbar(ERROR_TICKET_SERVER);
      return;
    }
    /*     setFormSent(true);
    setTimeout(() => {
      setFormSent(null);
      setSentResponse(true);
      setTimeout(() => {
        window.location.replace("/contact");
      }, 5000);
    }, 3000); */
  };

  const handler = (e) => defaultHandleChange(e, ticket, setTicket);

  return (
    <>
      {/* datos usuario + área de texto
        bloque enviado con éxito y error
    */}
      <Navbar />
      <div
        className="contactFormBlock "
        style={{ marginBottom: "50px", height: "100vh" }}
      >
        <>
          <h1 className="tituloFormContacto" style={theme.styles.title_text}>
            Contáctanos
          </h1>
          <form className="contact-form">
            <div className="row formGroup">
              <div className="col-12 col-md-12">
                <div className="mb-3">
                  <label htmlFor="disabledName" className="form-label">
                    Nombre
                  </label>
                  <input
                    className="form-control inputContactForm"
                    type="text"
                    id="disabledName"
                    placeholder={userData.firstname}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="disabledApellido" className="form-label">
                    Apellido
                  </label>
                  <input
                    className="form-control inputContactForm"
                    type="text"
                    id="disabledApellido"
                    placeholder={userData.lastname}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email
                  </label>
                  <input
                    className="form-control inputContactForm"
                    type="email"
                    id="exampleFormControlInput1"
                    placeholder={auth.user.email}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Asunto
                  </label>
                  <input
                    className="form-control inputContactForm "
                    type="text"
                    name="subject"
                    id="exampleFormControlInput1"
                    required
                    placeholder={ticket.subject}
                    value={ticket.subject}
                    onChange={handler}
                  />
                </div>
                {isMultiCourses && (
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Seleccione el curso
                    </label>
                    <select
                      className="form-control inputContactForm"
                      onInput={handleCourseSelection}
                    >
                      <option value={""}>Seleccione</option>
                      {moodle.courses &&
                        moodle.courses.map((c, idx) => {
                          return (
                            <option key={idx} value={c.id}>
                              {c.fullname}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Mensaje
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="description"
                    required
                    rows="3"
                    value={ticket.description}
                    onChange={handler}
                  ></textarea>
                </div>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-info buttonForm px-5"
                    onClick={handleSubmit}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginRight: "5px" }}>
                      {loading ? "Enviando" : "Enviar"}
                    </p>
                    {loading && (
                      <svg
                        className="ring"
                        viewBox="25 25 50 50"
                        strokeWidth="2"
                        stroke="#fff"
                      >
                        <circle cx="50" cy="50" r="15" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      </div>
      <Footer />
      <Snackbar
        message={snackbar.message}
        severity={snackbar.severity}
        visible={snackbar.visible}
      />
    </>
  );
};

export default ContactForm;

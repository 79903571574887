/**
 * Alumnos TuClase
 */

/* export const ipMoodle = "138.68.226.91";
export const baseURL = "https://par.tuclase.cl/";
export const tipddytoken = "4f7ded3dd876db415ad9e11406e8e78b";
export const moodleURL = `${baseURL}webservice/rest/server.php?wstoken=`;
export const iappUrl = "https://tuclase-rnu.iapp.cl/api/rnu";

export const API = {
  dev: "http://localhost:3000/",
  prod: "https://alumnos.tuclase.cl",
}; */

/**
 *
 * Modelo Pionero
 */

export const ipMoodle = "138.68.226.91";
export const baseURL = "https://admin.modelopionero.cl/";
export const tipddytoken = "af97ab5804170bb36f1575d21bc2cdb1";
export const moodleURL = `${baseURL}webservice/rest/server.php?wstoken=`;
export const iappUrl = "https://tuclase-rnu.iapp.cl/api/rnu";

export const API = {
  dev: "http://localhost:3000/",
  prod: "https://admin.modelopionero.cl/",
};

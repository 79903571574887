import React from "react";

import logo from "../../assets/logomp.png";

const Wrapper = ({ children }) => {
  return (
    <>
      <div className="container esp-log ">
        <img src={logo} alt="logo" className="posi-logo posi-log-b" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4  trans2 sac-new">
            <div className="fon-logo-new2 sec-desktop">
              <p className="">
                <img src={logo} alt="..." />
              </p>
              <p className="iniciar-new">Cambiar Contraseña</p>
            </div>
            <div className="fon-logo-new2 sec-movil">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-xs-8 col-md-8">
                    <p className="">
                      <img alt="img" src={logo} className="img-responsive" />
                    </p>
                  </div>
                  <div className="col-xs-4 col-md-4"></div>
                </div>
                <p className="iniciar-new">Recuperar Contraseña</p>
              </div>
            </div>
            {children}
          </div>
          <div className="col-md-7 text-center salir">
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-4"></div>
            </div>
            <h1 className="llamado">
              Elija su nueva <br />
              contraseña
            </h1>
            <p className="llamado-d"></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wrapper;

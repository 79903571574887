import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import icon from "./log-menu.png";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { THEME_CONTEXT } from "../../utils/theme";
export const CustomDialog = ({
  open,
  onConfirm,
  onCancel,
  title,
  message = null,
  confirm = "Confirmar",
  moodle,
  cancel = "Cancelar",
  courseid,
  countdown = false,
}) => {
  const [count, setCount] = useState(15);
  const navigate = useNavigate();
  let interval = null;

  React.useEffect(() => {
    if (countdown && open) {
      if (count === 0) {
        setCount(null);
        navigate(`/detailcourse/${moodle.courses[0].id}`);
      }

      if (!count) return;

      interval = setInterval(() => {
        let newcount = count - 1;
        setCount(newcount);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [count]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          style: {
            backgroundColor: "#465eb6",
            boxShadow: "none",
            paddingBottom: ".7rem",
            paddingRight: ".9rem",
          },
        }}
      >
        <DialogTitle
          className="text-white"
          sx={{ fontSize: "2rem", fontFamily: "Pop-Regular" }}
        >
          <img
            src={icon}
            style={{ width: 140, marginTop: ".5rem" }}
            alt={"..."}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="text-white"
            sx={{
              fontSize: "1.6rem",
              fontFamily: "Pop-Regular",
              marginLeft: ".2rem",
            }}
          >
            {message
              ? message
              : countdown
              ? ` Se redirigira a iniciar sesión en SENCE en ${count} segundos `
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={{
              fontSize: "1.4rem",
              fontFamily: "Pop-Medium",
              color: "#fff",
              backgroundColor: "#9aa2d5",
            }}
          >
            {confirm}
          </Button>
          <Button
            onClick={onCancel}
            variant="contained"
            sx={{
              fontSize: "1.4rem",
              fontFamily: "Pop-Medium",
              color: "#fff",
              backgroundColor: "#9aa2d5",
            }}
          >
            {cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

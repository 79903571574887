import React from "react";

export const ChangePasswordForm = ({
  handleChange,
  handleSubmit,
  loading,
  credentials,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      action="https://admin.modelopionero.cl/login/index.php"
      method="post"
    >
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="password"
          name="password"
          className="form-control box-ingreso2"
          placeholder="Nueva contraseña"
          value={credentials.password}
        />
      </div>
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="password"
          name="confirm_password"
          className="form-control box-ingreso2"
          placeholder="Repita nueva contraseña"
          value={credentials.confirm_password}
        />
      </div>
      <div className="col-md-12">
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block bot-text"
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-md mx-3"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Cambiar contraseña"
          )}
        </button>
      </div>
    </form>
  );
};

import * as d3 from "d3";
import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import { DARK_MODE, THEME_CONTEXT } from "../../../utils/theme";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ProgressChartItem = ({ course }) => {
  const [loading, setLoading] = React.useState(true);
  const [chartVisibility, setChartVisibility] = React.useState("none");
  const theme = useTheme();
  let chart = React.useRef(null);

  let data = [
    { label: "Completado", value: course.progress },
    { label: "Pendiente", value: 100 - course.progress },
  ];

  function renderChart() {
    let chartdata = d3.pie().value((d) => d.value)(data);
    let arc = d3.arc().innerRadius(60).outerRadius(100);
    let colors = d3.scaleOrdinal([
      THEME_CONTEXT.NAV_LIGHT,
      THEME_CONTEXT.DAY_MODE_BTN,
    ]);
    let svg = d3
      .select(chart.current)
      .attr("width", 300)
      .attr("height", 300)
      .style(
        "background-color",
        theme.mode === "dark" ? DARK_MODE.SECONDARY : "rgb(245, 245, 245)"
      )
      .append("g")
      .attr("transform", "translate(150,150)");

    let tooltip = d3
      .select(".chart-container")
      .append("div")
      .style("visibility", "hidden")
      .style("position", "absolute")
      .style("padding", "0.2rem 1rem")
      .style("border-radius", "5px")
      .style("color", "#fff");

    svg
      .append("g")
      .selectAll("path")
      .data(chartdata)
      .join("path")
      .attr("fill", (d, idx) => colors(idx))
      .attr("d", arc)
      .attr("stroke", theme.mode === "dark" ? "grey" : "#fff")
      .style("cursor", "pointer")
      .on("mouseover", (e, d) => {
        tooltip
          .style("visibility", "visible")
          .style(
            "background-color",
            d.data.label === "Completado"
              ? THEME_CONTEXT.NAV_LIGHT
              : THEME_CONTEXT.DAY_MODE_BTN
          )
          .text(`${d.data.label}: ${Math.floor(d.data.value)}%`);
      })
      .on("mousemove", (e, d) => {
        tooltip
          .style("top", e.pageY - 50 + "px")
          .style("left", e.pageX - 50 + "px");
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });
  }

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setChartVisibility("");
      renderChart();
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme.mode]);

  return (
    <>
      <div className="chart-container">
        {loading && (
          <div style={{ paddingTop: 20 }}>
            <Skeleton circle={true} width={200} height={200} color={"grey"} />
          </div>
        )}
        <svg ref={chart} style={{ display: chartVisibility }} />
        <p
          className="list-item-chart"
          style={{ ...theme.styles.title_text, display: chartVisibility }}
        >
          {course.displayname}
        </p>
      </div>
    </>
  );
};

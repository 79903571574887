import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { defaultHandleChange } from "../../utils/handler";
import { DEFAULT_CREDENTIALS } from "../../utils/state";
import { LoginForm } from "../../components/LoginForm";
import { Snackbar } from "../../components/Snackbar";
import {
  DEFAULT_SNACKBAR,
  ERROR_CREDENTIALS,
  ERROR_EMPTY_CREDENTIALS,
  SUCCESS_CREDENTIALS,
} from "../../utils/snackbar";

import logo from "../../assets/logomp.png";
import { useMoodleV2 } from "../../contexts/moodle";
// import { STRINGS } from "../../utils/strings";

const Login = () => {
  // if (localStorage.getItem("token")) window.location.replace("/listcourses");

  const auth = useAuth();
  const navigate = useNavigate();
  const [credentials, setCredentials] = React.useState(DEFAULT_CREDENTIALS);
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const moodlev2 = useMoodleV2();

  React.useEffect(() => {
    document.body.classList.add("imagen3");
    // localStorage.removeItem("token");
    // localStorage.removeItem("refresh");
    // localStorage.removeItem("username");
    // localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);
    auth.logout(moodlev2);
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    if (isEmpty) {
      setSnackbar(ERROR_EMPTY_CREDENTIALS);
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
      return;
    }
    let clean_username = credentials.username.replace(/\s/g, "").toLowerCase();
    try {
      setLoading(true);
      await auth.Login({ ...credentials, username: clean_username });
      setSnackbar(SUCCESS_CREDENTIALS);
      setTimeout(() => {
        setLoading(false);
        navigate("/listcourses");
        setSnackbar(DEFAULT_SNACKBAR);
        setCredentials(DEFAULT_CREDENTIALS);
        document.body.classList.remove("imagen3");
      }, 1000);
    } catch (error) {
      console.log(error.message);
      setSnackbar(ERROR_CREDENTIALS);
      setLoading(false);
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
    }
  };

  const handleChange = (e) =>
    defaultHandleChange(e, credentials, setCredentials);

  return (
    <>
      <div className="container esp-log" style={{ visibility: "hidden" }}>
        <img alt="logo" className="posi-logo posi-log-b" />
      </div>
      <div className="container ">
        <div className="row mx-4">
          <div className="col-md-1"></div>
          <div className="col-md-4  trans2 sac-new">
            <div className="fon-logo-new2 sec-desktop">
              <p className="">
                <img src={logo} alt="..." />
              </p>
              <p className="iniciar-new">Iniciar Sesión</p>
            </div>
            <div className="fon-logo-new2 sec-movil">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-xs-8 col-md-8">
                    <p className="">
                      <img alt="img" src={logo} className="img-responsive" />
                    </p>
                  </div>
                  <div className="col-xs-4 col-md-4"></div>
                </div>
                <p className="iniciar-new">Iniciar Sesión</p>
              </div>
            </div>
            <LoginForm
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              credentials={credentials}
              loading={loading}
            />
          </div>
          <div className="col-md-7 text-center salir">
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-4"></div>
            </div>
            <h1 className="llamado">
              Experiencias de <br />
              aprendizaje
            </h1>
            <p className="llamado-d">a la medida, actualizadas y efectivas</p>
          </div>
        </div>
      </div>
      <Snackbar
        severity={snackbar.severity}
        visible={snackbar.visible}
        message={snackbar.message}
      />
    </>
  );
};

export default Login;

import React from "react";
import "./index.css";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { Navbar } from "../../../components/Navbar";
import { useMoodle } from "../../../contexts/MoodleContext";
import { useTheme } from "../../../contexts/ThemeContext";
import { PROGRESS_DETAIL } from "../../../utils/state";
import { ProgressRowItemDetail } from "../ProgressRowItemDetail";
import { ProgressTable } from "../ProgressTable";
import { Table } from "@nejcm/react-skeleton";
import { ProgressTitle } from "../ProgressTitle";
import { useAuth } from "../../../contexts/AuthContext";
import { requestAutoLogin } from "../../../services/moodle/userauth";

export const ProgressDetail = () => {
  const theme = useTheme();
  const moodle = useMoodle();
  const location = useLocation();
  const params = useParams();
  const { user } = useAuth();
  const navigation = useNavigate();
  const [data, setData] = React.useState(null);
  const [key, setkey] = React.useState(null);
  const [authLoginConsumed, setAuthLoginConsumed] = React.useState(false);

  React.useEffect(() => {
    if (!location.state?.name) {
      navigation("/listcourses/progress/");
      return;
    }
    setTimeout(() => {
      fetchDetails();
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchDetails() {
    try {
      const [grades /* progress */] = await moodle.getProgressDetail(params.id);
      /*       console.log({ grades }); */
      const filterdata = grades.usergrades[0].gradeitems
        .filter((d) => d.itemname !== null)
        .filter((d) => d.itemname !== "")
        .filter((d) => !d.gradeishidden);
      /*       console.log({ progress }); */
      if (!key) {
        const login_key = await requestAutoLogin({ username: user.username });
        setkey(login_key);
      }
      setData(filterdata);
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <>
      <Navbar />
      <div
        style={{ ...styles.container, ...theme.styles.background_secondary }}
      >
        <div className="breadcrumb-container">
          <ul className="breadcrumb-ul">
            <Link to={"/listcourses"}>
              <li className="breadcrumb-li">Inicio</li>
            </Link>
            <span>/</span>
            <Link to={"/listcourses/progress/"}>
              <li className="breadcrumb-li">Historial</li>
            </Link>
            <span>/</span>
            <Link to={`/detailcourse/${params.id}`}>
              <li className="breadcrumb-li">{location.state?.name || null}</li>
            </Link>
          </ul>
        </div>
        <ProgressTitle title={PROGRESS_DETAIL.title} />
        {!data ? (
          <Table
            widths={["40%", "15%", "15%", "15%", "15%"]}
            rows={10}
            cols={["", "", "", "", ""]}
          />
        ) : (
          <ProgressTable headers={PROGRESS_DETAIL.headers}>
            <tr>
              <th scope="row" className="title-row-detail">
                {location.state?.name || null}
              </th>
            </tr>
            {data &&
              data.map((d, idx) => {
                return (
                  <ProgressRowItemDetail
                    key={idx}
                    detail={d}
                    login_key={key}
                    authLoginConsumed={authLoginConsumed}
                    setAuthLoginConsumed={setAuthLoginConsumed}
                  />
                );
              })}
          </ProgressTable>
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    margin: "0 auto",
    width: "80%",
    paddingTop: "10rem",
    minHeight: "100vh",
  },
};

import "./App.css";
import Routes from "./routes";
import { AuthProvider } from "./contexts/AuthContext";
import { MoodleProvider } from "./contexts/MoodleContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { Layout } from "./components/Layout";
import { MoodleProviderV2 } from "./contexts/moodle";

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <MoodleProvider>
          <MoodleProviderV2>
            <Routes />
          </MoodleProviderV2>
        </MoodleProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
